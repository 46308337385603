@import 'src/variables.scss';

.products-section {
    margin: 0 auto;
    margin-top: 150px;

    .title {
        color: $on-base;
        border-radius: 4px;
        padding: 6px 25px;
        background: whitesmoke;
        box-shadow: rgba(9, 60, 139, 0.4) 0px 2.95px 8.6px;
        margin: 0 15px;
        margin-bottom: 20px;
    }

    .products-container {
        margin: 15px;
        padding: 20px 0 40px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        border: none;

        .products-wrapper {
            margin: 0 auto;
            width: 100%;
            max-width: 500px;
            min-height: 400px;
            border: none;
            border-radius: 8px;
            background-color: whitesmoke;
            overflow: hidden;
            box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 6px;

            .products-title-wrapper {
                width: 100%;
                height: 80px;

                .products-title {
                    padding: 0 6px;
                    height: inherit;
                    width: 85%;
                    border-bottom-right-radius: 8px;
                    // min-height: 96px;
                    // text-wrap: nowrap;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $on-secondary;
                    background-color: $secondary;
                    text-transform: uppercase;
                }
            }

            .products-list-container {
                padding: 8px 18px;

                .products-list-wrapper {
                    display: grid;
                    grid-template-columns: 1fr;
                    column-gap: 20px;

                    .product {
                        border-bottom: 1px dotted;
                        border-bottom-color: $on-primary;
                        line-height: 22px;
                        font-weight: 400;
                        padding: 8px 0px;
                        text-transform: capitalize;

                        &:last-child {
                            border: 0;
                        }
                    }
                }
            }
        }
    }

}






@media (min-width:1200px) {
    .products-section {
        width: 1140px;

        .title {
            margin: 0;
        }
        .products-container {
            margin: 30px 0 50px;
        }
    }
}

// @media (max-width:1199px) {
//     .products-section {
//         // width: 940px;
//     }
// }

// @media (max-width:991px) {
//     .products-section {
//         // width: 720px;
//     }
// }

// @media (max-width: 768px) {
//     .products-section {
//         // width: 540px;
//         // width: 700px;
//     }
// }

@media (max-width: 576px) {

    .products-section {
        // width: 340px;
        width: auto;
    }
}














@media (min-width: 768px) {

    .products-section {
        .products-container {
            grid-template-columns: 1fr 1fr;
            .products-wrapper {

                &.fixed-grid {
                    grid-area: 1/1/3/2;
                }
                // .products-list-container {
                //     max-height: 396px;
                //     overflow-y: auto;
                // }
            }
        }
    }
}


@media (min-width: 1200px) {

    .products-section {
        .products-container {
            grid-template-columns: 1fr 1fr 1fr;

            .products-wrapper {
                min-height: unset;
            }
        }
    }
}
