@import 'src/variables.scss';

// contact-us page css
.contact-us-wrapper {
    margin: 0 25px;
    margin-top: 150px;

    .contact-form-info {
        // display: flex;
        // margin: 0 50px;
        padding: 25px 25px;
        margin-bottom: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3rem;
        justify-content: center;
        border-radius: 8px;
        background: whitesmoke;

        .contact-info {
            color: #051320;
            min-width: min-content;

            .contact-us-list {
                line-height: 1.5;
                padding: 0 30px;
                padding: 0 20px 0 10px;

                li {
                    margin-bottom: 6px;
                    min-height: 30px;

                    .contact-us-icon {
                        display: inline-flex;
                        align-items: center;
                        align-self: center;
                        float: left;
                        height: 24px;
                        width: 18px;
                    }

                    .contact-us-inner-info {
                        -webkit-margin-start: 38px;
                        margin-inline-start: 38px;
                    }

                    span {
                        box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
                        border-radius: 50%;
                        height: 30px;
                        width: 30px;
                        display: inline-flex;
                        justify-content: center;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }

            .contact-us-divider {
                position: absolute;
                bottom: 0px;
                right: calc(-1.5rem - 2px);
                border-left: 1px solid #051320;
                height: 100%;
            }
        }

        .contact-form {
            .contact-us-form {
                .form-name {
                    grid-area: name;
                }

                .form-email {
                    grid-area: email;
                }

                .form-message {
                    grid-area: message;
                }

                .form-number {
                    grid-area: number;
                }

                .form-send-msg-btn {
                    border-radius: 4px;
                    grid-area: send-msg-btn;
                }

                .contact-us-form-grid {
                    display: grid;
                    gap: 10px;
                    grid-template-columns: 1fr 1fr;
                    grid-template-areas:
                        'name email'
                        'message message'
                        'number send-msg-btn';
                }

                .form-name,
                .form-email,
                .form-number,
                .form-message,
                .form-send-msg-btn {
                    input,textarea,button {
                        padding: 12px 8px;
                        width: 100%;
                        border-radius: 4px;
                        // border: 1px solid #051320;
                        border: none;
                        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

                        &:focus-visible {
                            // outline: #76dcc2 auto 1px;
                            outline: rgba(8, 61, 137, 0.6) auto 1px;
                        }
                    }

                    &.invalid {
                        ::placeholder {
                            /* Chrome, Firefox, Opera, Safari 10.1+ */
                            color: red;
                            opacity: 1;
                            /* Firefox */
                        }

                        :-ms-input-placeholder {
                            /* Internet Explorer 10-11 */
                            color: red;
                        }

                        ::-ms-input-placeholder {
                            /* Microsoft Edge */
                            color: red;
                        }

                        input,
                        textarea,
                        button {
                            border: 1px solid red;

                            &:focus-visible {
                                outline: red auto 1px;
                            }
                        }
                    }
                }

                .form-send-msg-btn button {
                    background-color: $secondary;
                    color: $on-secondary;
                    cursor: pointer;
                }

                .form-send-msg-btn:hover {
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                    transition: 0.35s;
                }

                .form-send-msg-btn:click {
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
                }
            }

            .form-submitted {
                display: none;
                color: #C3922E;
                animation: all 0.5s;
                margin-top: 1rem;
                text-align: center;
                
                &.submitted {
                    display: block;
                }
            }
            
            .form-not-submit {
                display: none;
                color: red;
                animation: all 0.5s;
                margin-top: 1rem;
                text-align: center;
                
                &.not-submit {
                    display: block;
                }
            }
        }

        .contact-info,
        .contact-form {
            .main-heading {
                margin-bottom: 3rem;
                color: #051320;
                border-radius: 4px;
                padding: 6px 25px;
                background: whitesmoke;
                box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
                text-wrap: nowrap;
                text-align: center;
            }
        }

    }

    .contact-map {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;

        iframe {
            // width: calc(100% - 100px);
            width: 100%;
            height: 34rem;
            min-height: 480px;
            border: none;
            border-radius: 8px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}



@media (max-width:992px) {

    .contact-us-inner-info {
        margin-inline-start: 34px !important;
        font-size: 14px !important;
    }

    .contact-us-icon {
        height: 20px !important;
        width: 17px !important;
    }
}

@media (max-width:900px) {
    .contact-form-info {
        display: block !important;
        // margin: 0 25px !important;
        margin-bottom: 50px !important;
    }

    .contact-info,
    .contact-form {
        // width: 100% !important;
        max-width: 550px !important;
        margin: 0 auto !important;
        margin-bottom: 50px !important;

        .contact-us-divider {
            display: none !important;
        }
    }

    .contact-form {
        margin-bottom: 0 !important;
    }
}

@media (max-width:768px) {
    .contact-us-form-grid {
        grid-template-columns: 1fr !important;
        grid-template-areas:
            'name'
            'email'
            'message'
            'number'
            'send-msg-btn' !important;
    }
}

@media (max-width: 576px) {

    .contact-map iframe {
        height: 400px !important;
    }

    .contact-us-wrapper {
        width: auto;
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media (min-width: 1200px) {
    .contact-us-wrapper {
        width: 1140px;
        margin-left: auto;
        margin-right: auto;
    }
}
