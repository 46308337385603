@import 'src/variables.scss';

.home-wrapper {

    .hero-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100vh;
        width: 100%;
        background: $base url('../../assets/images/home/hero.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 48px;
        filter: saturate(3);

        .hero-text-wrapper {
            width: 60%;
            background: $interaction;
            padding: 42px 48px;
            border-radius: 0 8px 8px 0;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            .title {
                text-transform: uppercase;
                color: $on-base;
                font-size: 24px;
                margin-bottom: 0.5rem;
            }

            .subtitle {
                width: 75%;
                font-size: 42px;
                font-family: $base-font;
                line-height: 1;
                margin-bottom: 2rem;
            }

            .summary {
                width: 80%;
                font-size: 18px;
                font-family: $base-font;
                margin-bottom: 1rem;
                line-height: 1.4;
            }

        }
    }
}


@media (max-width:1200px) {
    .home-wrapper .hero-wrapper .hero-text-wrapper {
        width: 70%;
        padding: 30px 32px;
    }

    .home-wrapper .hero-wrapper .hero-text-wrapper .subtitle {
        font-size: 36px;
    }

    .home-wrapper .hero-wrapper .hero-text-wrapper .title {
        font-size: 20px;
    }
}

@media (max-width:992px) {
    .home-wrapper .hero-wrapper .hero-text-wrapper .subtitle {
        font-size: 28px;
    }

    .home-wrapper .hero-wrapper .hero-text-wrapper .title {
        font-size: 18px;
    }

    .home-wrapper .hero-wrapper .hero-text-wrapper .summary {
        width: 85%;
        font-size: 14px;
    }
}

@media (max-width:576px) {
    .home-wrapper .hero-wrapper .hero-text-wrapper {
        width: 75%;
        padding: 16px 20px;
    }

    .home-wrapper .hero-wrapper .hero-text-wrapper .subtitle {
        font-size: 22px;
        width: 95%;
    }

    .home-wrapper .hero-wrapper .hero-text-wrapper .title {
        font-size: 16px;
    }

    .home-wrapper .hero-wrapper .hero-text-wrapper .summary {
        width: 95%;
        font-size: 13px;
    }
}

// product css
.pro-wrp-uppr {
    margin-bottom: 48px;
}

.product-wrapper {
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(3, calc(33.3333% - 20px));
    gap: 2rem;
    margin: 0 auto 0 auto;

    .product {
        border-radius: 8px;
        overflow: hidden;
    }
}

.product-title {
    color: $on-secondary;
    background-color: $secondary;
    min-height: 80px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
        text-transform: capitalize;
    }
}

.product-img img {
    // width: 359.99px;
    width: 100%;
}

.product-info P {
    margin-bottom: 18px;
    font-family: "Philosopher", sans-serif;
    font-size: 22px;
    color: $secondary;
}

.product-info {
    padding: 30px;
    background-color: $on-secondary;
    display: flex;
    flex-direction: column;
    // align-items: center;
}

.product-info ul {
    margin-bottom: 18px;
    color: $secondary;
}

.product-info ul li:not(:last-child) {
    margin-bottom: 5px;
}

.note-icon {
    color: $on-primary;
    height: 11.5px;
    padding-right: 6px;
}

.learn-more-btn {
    display: flex;
    justify-content: center;
}

.learn-more-btn button {
    color: #051320;
}

@media (min-width:1200px) {
    .product-wrapper {
        width: 1140px;
    }
}

@media (max-width:1199px) {
    .product-wrapper {
        width: 940px;
    }

    // .product-img img {
    //     width: 293.33px;
    // }
}

@media (max-width:991px) {
    .product-wrapper {
        grid-template-columns: repeat(2, calc(((100% - 720px) / 2) + 345px));
        width: 720px;
    }

    // .product-img img {
    //     width: 345px;
    // }
}

@media (max-width: 768px) {
    .product-wrapper {
        grid-template-columns: 100%;
        width: 540px;
    }

    // .product-img img {
    //     width: 540px;
    // }
}

@media (max-width: 576px) {

    .product-wrapper {
        width: 340px;
    }
    // .product-wrapper,
    // .product-img img {
    //     width: 340px;
    // }
}

// end product css