@import 'src/variables.scss';

.header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: transparent;
    color: $on-base;
    height: 100px;
    width: 100%;
    z-index: 99;
    transition: 0.25s;
    background-color: $interaction;

    // &.floating {
    //     background-color: $interaction;
    //     box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    // }

    .brand-wrapper {
        flex: 1;
        flex-shrink: 0;

        .brand {
            height: 100px;
            width: auto;
            padding-left: 42px;
        }
    }

    .menu-wrapper {
        flex: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        // padding-right: 28px;
        height: 100px;
        // background-color: $interaction;

        .menu-item {
            padding: 10px 6px;

            .menu-link {
                font-size: 1.2rem;
                font-family: $highlight-font;
                font-weight: 700;
                line-height: 1.5;
                letter-spacing: $highlight-letter-spacing;
                white-space: nowrap;
            }
        }

        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     left: -36px;
        //     display: block;
        //     width: 100px;
        //     height: 100%;
        //     clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
        //     background-color: $interaction;
        // }
        // 
        // &::after {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 100%;
        //     display: block;
        //     width: 3rem;
        //     height: 100px;
        //     background-color: $interaction;
        // }
    }

    // .appointment-wrapper {
    //     flex-shrink: 0;
    //     display: flex;
    //     justify-content: flex-start;
    //     align-items: center;
    //     width: auto;
    //     padding: 16px;
    //     height: 100%;
    //     background-color: $secondary;

    //     .app-button {
    //         font-size: 1rem;
    //     }

    //     &::before {
    //         content: '';
    //         position: absolute;
    //         top: 0;
    //         left: -36px;
    //         display: block;
    //         width: 100px;
    //         height: 100%;
    //         clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
    //         background-color: $secondary;
    //     }

    //     &::after {
    //         content: '';
    //         position: absolute;
    //         top: 0;
    //         left: 100%;
    //         display: block;
    //         width: 3rem;
    //         height: 100px;
    //         background-color: $secondary;
    //     }

    //     // .appointment-btn {}

    // }

}

.mobile-nav-toggle {
    display: none;
}

@media (max-width:992px) {
    .min-menu-color {
        background-color: $interaction;
    }

    .mobile-nav-toggle[aria-expanded="false"]>.menu-button {
        display: block;
    }

    .mobile-nav-toggle[aria-expanded="false"]>.close-button {
        display: none;
    }

    .mobile-nav-toggle[aria-expanded="true"]>.close-button {
        display: block;
    }

    .mobile-nav-toggle[aria-expanded="true"]>.menu-button {
        display: none;
    }

    .menu-wrapper {
        display: flex;
        flex-direction: column;
        height: 100vh;
        position: fixed;
        // inset: 0 0 0 30%;
        inset: 0 50% 0 0%;
        background: $on-secondary;
        padding: min(13vh, 10rem) 2em;
        // transform: translateX(100%);
        transform: translateX(240%);
        z-index: 1;
    }

    .menu-wrapper[data-visible="true"] {
        transform: translateX(0%);
    }

    .mobile-nav-toggle[aria-expanded="true"] {
        position: fixed;
        background: transparent;
        width: 2rem;
        aspect-ratio: 1;
        top: 2rem;
        left: 1rem;
        border: 0;
        z-index: 2;
    }

    .mobile-nav-toggle {
        text-align: center;
        margin: auto;
        display: block;
        position: absolute;
        z-index: 2;
        background: transparent;
        width: 2rem;
        aspect-ratio: 1;
        top: 2rem;
        left: 1rem;
        border: 0;
    }

    .header-wrapper .menu-wrapper {
        align-items: baseline;
        justify-content: flex-start;
        height: 100vh;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

}

@media (max-width:576px) {
    .header-wrapper .appointment-wrapper::before {
        clip-path: unset;
    }
    
    .header-wrapper .appointment-wrapper::before {
        left: 0;
    }
}