@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Philosopher:wght@400;700&display=swap');
@import 'src/variables.scss';

*,
*::after,
*::before {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  vertical-align: baseline;
  position: relative;
}

body {
  margin: 0;
  font-family: $base-font;
  line-height: $base-line-height;
  letter-spacing: $base-letter-spacing;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $base;
}

.app-container {
  padding: 0 1rem;

  @media only screen and (min-width: 577px) and (max-width: 992px) {
    padding: 0 1rem;
  }

  @media only screen and (min-width: 992px) {
    padding: 0 3rem;
  }
}

.app-button {
  font-family: $highlight-font;
  line-height: $highlight-line-height;
  letter-spacing: $highlight-letter-spacing;
  color: $text-main-color;
  background-color: transparent;
  padding: 0.5rem 1rem;
  border: 1px solid currentColor;
  outline: none;
  border-radius: 4px;
  user-select: none;
  transition: 0.35s;
  cursor: pointer;

  &.active,
  &:hover {
    color: $on-secondary;
    box-shadow: inset 0 -4em 0 0 $secondary;
    border-color: $secondary;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $highlight-font;
  line-height: $highlight-line-height;
  letter-spacing: $highlight-letter-spacing;
}

p, span {
  font-family: $base-font;
  line-height: $base-line-height;
  letter-spacing: $base-letter-spacing;
}

a {
  color: $text-main-color;
  text-decoration: none;
  &:hover,
  &.active {
    color: $on-base;
  }
}

.highlight-font {
  font-family: $highlight-font;
  line-height: $base-line-height;
  letter-spacing: normal !important;
}

ul {
  padding: 0;
  list-style-type: none;
}

.mb-5{
  margin-bottom: 3rem;
}

@media (max-width:768px) {
  .main-heading {
      margin-bottom: 2rem;
      font-size: 28px;
  }
}

@media (max-width:576px) {
  .main-heading {
      margin-bottom: 1.5rem;
      font-size: 24px;
  }
}