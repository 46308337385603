@import 'src/variables.scss';

.section-display-products {
    margin: 0 10px;
    margin-top: 150px;
    margin-bottom: 50px;
    width: auto;

    .display-products-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.5rem;

        .display-product-menu-wrapper {
            padding: 12px 8px 8px;
            min-width: 250px;
            height: max-content;
            border-radius: 8px;
            box-shadow: 1px 4px 4px rgba(5, 19, 32, 0.2);

            .product-menu-title {
                font-size: 20px;
                margin-bottom: 10px;
                border-bottom: 2px solid #051320;
            }

            .product-menu-list {
                .product-menu-item {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    padding: 0px 10px;
                    margin-bottom: 6px;
                    border-bottom: 2px dotted;

                    a {
                        width: 100%;
                        padding: 6px 0;
                    }

                    &:last-child {
                        margin: 0;
                        border: none;
                    }

                    &:hover {
                        transition: 0.2s;
                        background-color: $secondary;
                        border-radius: 4px;

                        a {
                            color: $on-secondary;
                        }
                    }

                    &.active-menu {
                        background-color: $secondary;
                        border-radius: 4px;

                        a {
                            color: $on-secondary;
                        }
                    }
                }
            }
        }

        .display-product-info-wrapper {
            padding: 8px;
            border-radius: 8px;
            grid-row: 1;
            grid-area: 1/1;

            img {
                width: 100%;
                mix-blend-mode: multiply;
            }

            .product-name {
                margin-bottom: 10px;
                font-size: 24px;
                text-transform: uppercase;
                width: fit-content;
                color: #051320;

                h2 {
                    line-height: 1.2;
                }
            }

            .product-variant-name {
                margin-bottom: 30px;
                font-size: 22px;
                text-transform: uppercase;
                width: fit-content;
                color: #051320;
            }

            .product-description-with-img {
                display: grid;
                gap: 1rem;

                .product-image {
                    text-align: center;

                    img {
                        width: 330px;
                        height: 140px;
                    }
                }
            }


            table {
                strong {
                    &.text-danger {
                        color: #ff0000;
                    }
                }

                span {
                    &.text-danger {
                        color: #ff0000;
                    }
                }
            }

            .scrollable-product-info-table-wrapper {
                padding: 50px 0 0;
                max-width: calc(100vw - 16px - 10px - 20px);
                overflow-x: auto;


                .detailed-table {
                    border-collapse: collapse;
                    text-align: center;
                    line-height: 1.5;

                    caption {
                        margin-bottom: 20px;
                        font-size: 22px;
                        text-transform: uppercase;
                        width: -moz-fit-content;
                        width: fit-content;
                        color: #051320;
                    }

                    tr {

                        td,
                        th {
                            border: 1px solid;
                            vertical-align: middle;
                        }

                        th {
                            padding: 4px 4px 8px;
                        }

                        td {
                            padding: 4px 3px 6px;
                        }

                        &:nth-child(1) {
                            th {
                                // background-color: #144e80;
                                // background-color: rgba(20,78,128, 0.7);
                                color: $on-secondary;
                                background-color: $secondary;
                            }
                        }

                        &:nth-child(2) {
                            th {
                                color: $on-secondary;
                                background-color: $secondary;
                                // background-color: #1962a0;
                                // background-color: rgba(25,98,160, 0.7);
                                padding: 2px 6px;
                            }
                        }
                    }
                }
            }

            .dredge-hose-table {
                border-collapse: collapse;
                text-align: center;
                line-height: 1.5;
                border: 1px solid;

                caption {
                    margin-bottom: 20px;
                    font-size: 22px;
                    text-transform: uppercase;
                    width: -moz-fit-content;
                    width: fit-content;
                    color: #051320;
                }

                tr {

                    td,
                    th {
                        // border: 1px solid;
                        vertical-align: middle;
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                    }

                    th {
                        padding: 10px 8px;
                    }

                    td {
                        padding: 4px 3px 6px;
                    }

                    &:nth-child(1) {
                        th {
                            // background-color: #144e80;
                            // background-color: rgba(20,78,128, 0.7);
                            background-color: #051320;
                            color: #76dcc2;
                        }
                    }

                    &:nth-child(2) {
                        th {
                            background-color: #051320;
                            color: #76dcc2;
                            // background-color: #1962a0;
                            // background-color: rgba(25,98,160, 0.7);
                            padding: 2px 6px;
                        }
                    }
                }
            }

            .description-table {
                line-height: 1.8;

                tr {
                    td {
                        vertical-align: middle;
                        padding-top: 4px;
                        padding-bottom: 8px;

                        &:first-child {
                            min-width: 130px;
                            width: 130px;
                            max-width: 150px;
                        }
                    }
                }
            }
        }
    }
}



@media (min-width: 1024px) {
    .section-display-products {

        .display-products-container {
            grid-template-columns: max-content 1fr;

            .display-product-info-wrapper {
                grid-row: unset;
                grid-area: unset;

                .product-description-with-img {
                    grid-template-columns: 1fr min-content;

                    .product-image {
                        img {
                            width: 350px;
                        }
                    }

                    .product-description {
                        grid-row: 1;
                        grid-area: 1/1;
                    }
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .section-display-products {
        margin-left: auto;
        margin-right: auto;
        width: 1140px;
    }
}

// table {
//     line-height: 1.5;

//     tr {
//         td {
//             vertical-align: middle;
//             padding-top: 4px;
//             padding-bottom: 8px;

//             &:first-child {
//                 min-width: 130px;
//                 width: 130px;
//                 max-width: 150px;
//             }
//         }
//     }

//     &.detailed-table {
//         border-collapse: collapse;
//         text-align: center;

//         tr {

//             td,
//             th {
//                 border: 1px solid;
//                 vertical-align: middle;
//                 // padding-bottom: 0px;
//             }

//             &:nth-child(1) {
//                 th {
//                     // background-color: #144e80;
//                     // background-color: rgba(20,78,128, 0.7);
//                     background-color: #051320;
//                     color: #76dcc2;
//                 }
//             }

//             &:nth-child(2) {
//                 th {
//                     background-color: #051320;
//                     color: #76dcc2;
//                     // background-color: #1962a0;
//                     // background-color: rgba(25,98,160, 0.7);
//                     padding: 2px 6px;
//                 }
//             }
//         }
//     }
// }