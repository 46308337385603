@import 'src/variables.scss';

.about-us-wrapper {
    margin-top: 150px;
}

.get-info {
    width: 60%;
    margin: 0 0 0 auto;
    margin-bottom: 3rem;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 8px 0 0 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.main-heding {
    text-align: center;
    margin-bottom: 2rem;
    color: #051320;
}

.benefit-point-list {
    list-style: disc;
}

.benefit-point-list li {
    margin-bottom: 10px;
    word-spacing: 2px;
    line-height: 1.5;
    color: #051320;
}

.about-us-img {
    display: flex;
    align-items: center;
    width: 100%;
    background: #eceeee url('../../assets/images/about-us.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 48px;
}

.company-img {
    margin: 0 auto;
    margin-bottom: 3rem;
    width: 80%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    // background-image: url('../../assets/images/companyimg.jpg');
    // margin-bottom: 48px;
    background-image: linear-gradient(144deg, hsla(0%, 0%, 0%, calc(100% - 75%)) 0%, hsla(0%, 0%, 0%, calc(100% - 75%)) 100%), url('../../assets/images/companyimg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.co-info {
    display: flex;
    text-align: center;
    align-items: center;
    width: 80%;
}

.co-100 {
    font-size: 100px;
    color: #ffffff;
    width: 50%;
    font-weight: bold;
    font-family: "Philosopher", sans-serif;

}

.co-detail {
    font-size: 70px;
    color: #ffffff;
    width: 50%;
    font-size: 40px;
    font-weight: bold;
    font-family: "Philosopher", sans-serif;
    line-height: 1.3;
    letter-spacing: 0.55px;
}

.deal-with {
    display: flex;
    margin: 0 auto;
    gap: 3rem;
    margin-bottom: 3rem;
}

.deal-img {
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.deal-img,
.deal-info {
    width: 50%;
    border-radius: 8px;
    overflow: hidden;
}

.deal-img img {
    max-width: 100%;
    height: 100%;
    max-height: 100%;
}

.deal-info {
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: column;
}

.deal-heading {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 20px;
}

.deal-info-list {
    padding-left: 25px;
}

.deal-info-list li {
    margin-bottom: 10px;
    word-spacing: 2px;
    line-height: 1.5;
    color: #051320;
    font-weight: 400;
}

.direct-chat-appointment {
    background-color: #051320;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 200px;
    padding: 0 50px 0 0;
}

.direct-chat-appointment-btn {
    display: flex;
}

.direct-chat-appointment-btn>div {
    padding: 25px;
}

.bullet-icon svg {
    width: 12px;
}

.deal-info-list li {
    display: flex;
}

.bullet-icon svg {
    height: 25px;
    fill: $on-primary;
}

.deal-info-point{
    -webkit-margin-start: 10px;
    margin-inline-start: 10px;
}

@media (min-width: 1200px) {
    .deal-with {
        max-width: 1140px;
    }
}

@media (max-width: 1199px) {
    .deal-img {
        display: none;
    }

    .deal-info {
        width: 90%;
        margin-left: 32px;
    }
}

@media (max-width: 1200px) {
    .co-100 {
        font-size: 80px;
    }

    .co-detail {
        font-size: 32px;
    }
}

@media (max-width: 992px) {
    .get-info {
        width: 75%;
    }

    .co-info {
        display: block;
        border-radius: 50%;
        text-align: left;
    }

    .co-100 {
        width: 100%;
        font-size: 70px;
        margin-bottom: 20px;
    }

    .co-detail {
        width: 100%;
        font-size: 27px;
    }
}

@media (max-width:768px) {
    .benefit-point-list li {
        font-size: 14px;
    }
}

@media (max-width:576px) {
    .benefit-point-list li {
        font-size: 12px;
    }

    .co-100 {
        font-size: 60px;
    }

    .co-detail {
        font-size: 25px;
    }

    .deal-heading {
        font-size: 16px;
    }

    .deal-info-list li {
        font-size: 14px;
    }
}