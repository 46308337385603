@import 'src/variables.scss';

.footer-wrapper {
    display: grid;
    // grid-template-columns: repeat(2, 1fr);
    gap: 48px;
    padding: 1.25rem 5rem 1.25rem 7rem;
    background-color: #ffffff;
    justify-items: center;
    justify-content: space-around;
    display: flex;

    .brand-wrapper {
        .brand {
            height: 95px;
            width: auto;
            margin-bottom: 16px;
        }

        .message {
            font-size: 14px;
            margin-bottom: 12px;
            max-width: 450px;
            line-height: 1.5;
        }

        .clo-sec {
            // color: $text-main-color;
            border-color: $secondary;
        }
    }

    .inner-footer-wrapper {
        align-self: center;

        .footer-classic-list>li+li {
            margin-top: 12px;
        }

        .footer-classic-title {
            margin-bottom: 25px;
        }
    }

    .inner-footer-wrapper h3 {
        font-size: 17px;
    }
}


.footer-wrapper:after {
    left: 46px;
    background-color: $secondary;
    position: absolute;
    top: 0;
    content: '';
    display: block;
    width: 15px;
    height: 100%;
}

.footer-wrapper:before {
    left: 16px;
    background-color: $secondary;
    position: absolute;
    top: 0;
    content: '';
    display: block;
    width: 20px;
    height: 100%;
}

@media only screen and (min-width: 577px) and (max-width: 992px) {
    .footer-wrapper {
        padding: 1.25rem 1rem 1.25rem 7rem;
        // grid-template-columns: repeat(2, 1fr);
        .brand-wrapper .message {
            max-width: 250px;
        }

    }
}

@media only screen and (min-width: 992px) {
    .footer-wrapper {
        padding: 2.2rem 5rem 2.2rem 9rem;
    }
}

@media only screen and (max-width: 576px) {
    .footer-wrapper {
        padding: 2.2rem 3rem 2.2rem 7rem;
        // grid-template-columns: repeat(1, 1fr);
        flex-direction: column;
        justify-items: left;

        .inner-footer-wrapper {
            align-self: flex-start;
        }
    }

   

    .inner-footer-wrapper .footer-classic-list>li {
        font-size: 14px;
    }

    // .footer-classic-list>li {
    //     display: inline-block;
    //     vertical-align: middle;
    //     margin-left: 15px;
    // }

    // .footer-wrapper .inner-footer-wrapper .footer-classic-list>li+li {
    //     margin-top: 0;
    // }

    // .footer-classic-list {
    //     margin-left: -15px;
    // }
}