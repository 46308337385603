$base-font: 'Montserrat', sans-serif;
$base-line-height: 1.2;
$base-letter-spacing: normal;
$highlight-font: 'Philosopher', sans-serif;
$highlight-line-height: 1.3;
$highlight-letter-spacing: 0.55px;

$theme-color: #C3922E;
$text-main-color: #051320; 

$base: #eceeee;
$on-base: #C3922E;

$primary: #76dcc2;
$on-primary: #C3922E;

$secondary: #C3922E;
$on-secondary: #ffffff;

$interaction: #ffffff;
$on-interaction: #C3922E;