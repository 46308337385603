@import 'src/variables.scss';

.enquire-us-section {
    margin: 0;
    margin-top: 150px;

    .enquire-us-wrapper {
        margin-bottom: 50px;
        margin-left: 10px;
        margin-right: 10px;

        .heading {
            padding: 6px 25px;
            width: max-content;
            margin-bottom: 30px;
            color: #051320;
            border-radius: 4px;
            background: whitesmoke;
            box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
            text-wrap: nowrap;
            text-transform: capitalize;
            // text-align: center;
        }

        .enquire-us-form-wrapper {
            padding: 16px 12px;
            border-radius: 8px;
            overflow: hidden;
            background: whitesmoke;

            .enquire-us-form {
                display: grid;
                gap: 15px;
                grid-template-columns: 1fr;

                .name,
                .email,
                .number,
                .subject,
                .message,
                .send-msg-btn {
                    border-radius: 4px;

                    input,
                    textarea,
                    button {
                        width: 100%;
                        padding: 12px 8px;
                        border-radius: 4px;
                        font-size: 16px;
                        // border: 1px solid #051320;
                        border: none;
                        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

                        &:focus-visible {
                            // outline: #76dcc2 auto 1px;
                            outline: rgba(8, 61, 137, 0.6) auto 1px;
                        }
                    }

                    &.invalid {
                        ::placeholder {
                            /* Chrome, Firefox, Opera, Safari 10.1+ */
                            color: red;
                            opacity: 1;
                            /* Firefox */
                        }

                        :-ms-input-placeholder {
                            /* Internet Explorer 10-11 */
                            color: red;
                        }

                        ::-ms-input-placeholder {
                            /* Microsoft Edge */
                            color: red;
                        }

                        input,
                        textarea,
                        button {
                            border: 1px solid red;

                            &:focus-visible {
                                outline: red auto 1px;
                            }
                        }
                    }
                }

                .message textarea {
                    resize: none;
                }

                .send-msg-btn button {
                    // text-align: end;
                    width: fit-content;
                    padding: 12px 50px;
                    background-color: $secondary;
                    color: $on-secondary;
                    cursor: pointer;

                    &:hover {
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                        transition: 0.35s;
                    }

                    &:click {
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
                    }
                }
            }

            .form-submitted {
                display: none;
                margin-top: 1rem;
                color: #C3922E;
                animation: all 0.5s;
                
                &.submitted {
                    display: block;
                }
            }
            
            .form-not-submit {
                display: none;
                color: red;
                margin-top: 1rem;
                animation: all 0.5s;
                
                &.not-submit {
                    display: block;
                }
            }
        }
    }
}




@media (min-width: 576px) {
    .enquire-us-section {

        .enquire-us-wrapper {
            margin-left: 20px;
            margin-right: 20px;

            .enquire-us-form-wrapper {
                padding: 30px 40px;
            }
        }
    }
}

@media (min-width: 768px) {
    .enquire-us-section {

        .enquire-us-wrapper {
            width: auto;
            margin-left: 10px;
            margin-right: 10px;

            .enquire-us-form-wrapper {
                padding: 30px 40px;

                .enquire-us-form {
                    grid-template-columns: 1fr 1fr;
                    gap: 20px;

                    .message {
                        grid-area: 3/1/4/3;
                        ;
                    }
                }

            }
        }
    }
}

@media (min-width: 1200px) {
    .enquire-us-section {

        .enquire-us-wrapper {
            width: 1140px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}